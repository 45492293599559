.block-container {
    display: flex;
    padding: 60px 20px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    align-self: stretch;
}

.block-title {
    color: rgba(255, 255, 255, 0.80);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 26px */
}

.block-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.block-logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 60px;
    align-self: stretch;
}

/* Mobile Styles */
@media (max-width: 767px) {
    .block-container {
        padding: 30px 10px;
        gap: 20px;
    }

    .block-title {
        font-size: 16px;
        text-align: center;
    }

    .block-logos {
        gap: 30px;
        justify-content: center;
    }

    .logo-wrapper {
        width: auto;  /* Adjust as needed */
        height: auto; /* Adjust as needed */
    }
}
