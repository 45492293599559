.card-container {
    display: flex;
    padding: 60px;
    align-items: flex-start;
    gap: 60px;
    align-self: stretch;
    border-radius: 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%);
    box-shadow: 0px 0.5px 0px 0px rgba(255, 255, 255, 0.50) inset, 0px -2px 40px 0px rgba(187, 155, 255, 0.15), 0px -2px 10px 0px rgba(233, 223, 255, 0.30);
    z-index: 2;
}

.card-content-left {
    display: flex;
    width: 560px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.card-content-icon-wrapper {
    display: flex;
    width: 460px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    /* box-shadow: 0px 0px 36.45467px 0px rgba(201, 37, 171, 0.75); */
}

.card-content-icon-image {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
}

.blue-shadow {
    filter: drop-shadow(0 0 12.5px #591DDD);
}

.purple-shadow {
    filter: drop-shadow(0 0 12.5px #9525C9);
}

.pink-shadow {
    filter: drop-shadow(0 0 12.5px #C925AB);
}

.card-content-left-card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.card-content-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    color: #ECECEC;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 41.6px */
    letter-spacing: -0.16px;
}

.card-content-text {
    align-self: stretch;
    color: #ECECEC;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
}

.card-content-right {
    flex: 1 0 0;
    display: flex; 
    flex-direction: row;
    justify-content: flex-start; /* Aligns items to the start */
    position: relative; /* Sets the positioning context */
    overflow: hidden; /* Hides overflow */
}

.card-image,
.card-image-2,
.card-image-3 {
    width: 248px;
    height: 504px;
    background-size: cover; /* Covers the entire div */
    background-repeat: no-repeat;
    background-position: center; /* Centers the image */
}

.card-image {
    margin-left: 0;
    z-index: 3; /* Brings this image to the front */
}

.card-image-2 {
    margin-left: -124px; /* Overlaps halfway */
    z-index: 2; /* Positions this image behind the first */
}

.card-image-3 {
    margin-left: -124px; /* Overlaps halfway */
    z-index: 1; /* Positions this image behind the second */
}

.card-portrait {
    width: 452px;
    height: 425px;
    flex-shrink: 0;
    border-radius: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

/* Mobile Styles */
@media (max-width: 767px) {
    .card-container {
        flex-direction: column;
        padding: 30px;
        gap: 30px;
        z-index: 2;
    }

    .card-content-left {
        width: auto;
    }

    .card-content-icon-wrapper {
        width: auto;
    }

    .card-content-title {
        font-size: 24px;
    }

    .card-content-text {
        font-size: 12px;
    }

    .card-content-right {
        width: 100%;
        flex-direction: row; /* Ensures children are arranged in a row */
        align-items: center;
        position: relative; /* Sets the positioning context for absolutely positioned children */
        justify-content: center;
    }

    .card-image,
    .card-image-2,
    .card-image-3 {
        width: 160px; /* Adjust to your preference */
        height: 326px; /* Adjust to your preference */
    }

    .card-image-2 {
        margin-left: -70px; /* Adjust this value to control overlap */
    }

    .card-image-3 {
        margin-left: -70px; /* Adjust this value to control overlap */
    }

    .card-portrait {
        width: 100%;
        height: 452px;
    }
}