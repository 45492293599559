.navbar {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  background: #0B0121;
}

.navbar-container {
  display: flex;
  width: 1200px;
  justify-content: center;
  align-items: center;
}

.navbar-content {
  display: flex;
  width: 1200px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.navbar-brand {
  display: flex;
  align-items: flex-start;
}

.navbar-menu {
  display: flex;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
}

.navbar-link {
  display: flex;
  padding: 24px 12px;
  align-items: flex-start;
}

.navbar-link-text {
  color: #ECECEC;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-decoration: none;
}

.navbar-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #ECECEC;
  text-align: left;
}

.navbar-button-text {
  color: #0B081C;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

/* Mobile */
@media (max-width: 767px) {
  .navbar-container,
  .navbar-content {
      width: 100%;
      padding: 0 20px;
  }

  .navbar-menu {
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
  }

  .navbar-button {
      width: 100%;
      text-align: right;
  }
}