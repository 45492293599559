.contact {
    display: flex;
    padding: 110px 20px 110px 20px;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    align-self: stretch;
    background: url('../../resources/background_merged.png'), lightgray 50% / cover no-repeat;
}

.contact-container {
    display: flex;
    width: 960px;
    flex-direction: column;
    align-items: center;
    gap: 80px;
}

.contact-title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.contact-pill {
    display: flex;
    width: 417.622px;
    padding: 9.905px 19.811px;
    justify-content: center;
    align-items: center;
    border-radius: 61.909px;
    border: 1.238px solid rgba(255, 255, 255, 0.30);
}

.contact-pill-text {
    color: #F2F4F8;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', sans-serif;
    font-size: 17.334px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 26.002px */
}

.contact-title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.contact-title-main-text {
    align-self: stretch;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 80px */
    letter-spacing: -2.4px;
    background: linear-gradient(146deg, #ECECEC 20.35%, #ECECEC 128.73%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-paragraph-wrapper-contact {
    display: flex;
    width: 800px;
    justify-content: center;
    align-items: flex-start;
}

.contact-paragraph-text {
    flex: 1 0 0;
    color: rgba(236, 236, 236, 0.65);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%; /* 28.8px */
}

.contact-actions {
    display: flex;
    padding-top: 16px;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

/* Mobile view */
@media (max-width: 767px) {
    .contact {
        padding: 60px 20px;
        gap: 40px;
    }

    .contact-container {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .contact-title-section {
        gap: 16px;
    }

    .contact-pill {
        width: auto;
        padding: 10px 20px;
        border-radius: 30px;
    }

    .contact-pill-text {
        font-size: 18px;
    }

    .contact-title-main-text {
        margin-top: 10px;
        font-size: 35px;
        line-height: 1.2;
        letter-spacing: -1.0px;
    }

    .contact-paragraph-wrapper-contact {
        width: 100%;
        box-sizing: border-box;
    }

    .contact-paragraph-text {
        font-size: 16px;
        line-height: 1.5; 
    }
}