.contact-form-container {
    display: flex;
    height: 472px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 24px;
    box-shadow: 0px -2px 40px 0px rgba(187, 155, 255, 0.15), 0px -2px 10px 0px rgba(233, 223, 255, 0.30), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.50) inset;
    overflow: hidden;
}

.contact-form {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-container {
    display: flex;
    padding: 60px;
    align-items: flex-start;
    /* gap: 160px; */
    align-self: stretch;
    border-radius: 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%);
    box-shadow: 0px 0.5px 0px 0px rgba(255, 255, 255, 0.50) inset, 0px -2px 40px 0px rgba(187, 155, 255, 0.15), 0px -2px 10px 0px rgba(233, 223, 255, 0.30);
    z-index: 2;
}

.form-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.form-content-icon-wrapper {
    display: flex;
    width: 460px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    /* box-shadow: 0px 0px 36.45467px 0px rgba(201, 37, 171, 0.75); */
}

.form-content-icon-image {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
}

.blue-shadow {
    filter: drop-shadow(0 0 12.5px #591DDD);
}

.purple-shadow {
    filter: drop-shadow(0 0 12.5px #9525C9);
}

.pink-shadow {
    filter: drop-shadow(0 0 12.5px #C925AB);
}

.form-content-sub-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.form-content-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    color: #ECECEC;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 41.6px */
    letter-spacing: -0.16px;
}

.form-content-top-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

input, textarea {
    padding: 12px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fafafa;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #333;
    outline: none;
    transition: border-color 0.3s;
}

input {
    flex: 1;
}

textarea {
    display: flex;
    flex: 1;
    width: 96.6%;
    resize: none;
    height: 160px;
    margin-top: 10px;
}

input:focus, textarea:focus {
    border-color: #9525C9;  /* Change this color to your brand color */
}

.title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.title-main-text {
    align-self: stretch;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 80px */
    letter-spacing: -2.4px;
    background: linear-gradient(146deg, #ECECEC 20.35%, #ECECEC 128.73%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.paragraph-wrapper {
    display: flex;
    width: 600px;
    justify-content: center;
    align-items: flex-start;
}

.paragraph-text {
    flex: 1 0 0;
    color: rgba(236, 236, 236, 0.65);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%; /* 28.8px */
}

/* Mobile Styles */
@media (max-width: 767px) {
    .contact-form-container {
        height: auto;
        box-shadow: none;
        margin-top: -30px;
    }
    
    .form-container {
        padding: 30px;
    }
    
    .form-content {
        gap: 16px;
    }
    
    .form-content-title {
        font-size: 24px;
    }
    
    .form-content-top-row {
        flex-direction: column;
        gap: 10px;
    }

    .form-content-container {
        justify-content: center;
        align-items: center;
        width: 92%;
    }
    
    input, textarea {
        width: 100%;
    }
    
    textarea {
        height: 120px;
    }
    
    .title-main-text {
        font-size: 48px;
    }
    
    .paragraph-wrapper {
        width: 100%;
        padding: 0 10px;  /* Adds some padding */
    }

    .paragraph-text {
        font-size: 16px;
    }
}
