.footer-section {
    display: flex;
    padding: 64px 120px;  /* Increased padding to ensure ample space on the sides */
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.footer-container {
    display: flex;
    width: 100%;  /* Set to 100% to span the entire width */
    padding: 0;
    flex-direction: row;
    justify-content: space-between;  /* Space columns equally */
}

.footer-column {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-left {
    flex: 1;
    text-align: left;
}

.footer-center {
    flex: 1;
    text-align: center;
}

.footer-right {
    flex: 1;
    text-align: right;
}

.social-icons {
    display: flex;
    gap: 20px;
}

.social-icons img {
    width: 24px;
    height: auto;
}

.footer-content-left-copyright {
    color: #939393;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.16px;
    text-align: center;
    width: 100%;
}

/* Mobile */
@media (max-width: 767px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-column {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;  /* or whatever spacing you prefer */
    }

    .social-icons {
        justify-content: center;
    }

    .footer-content-left-copyright {
        font-size: 12px;
    }
}