.members-container {
    display: flex;
    padding: 60px 120px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    position: relative; 
    overflow: hidden;
    background: linear-gradient(to bottom, #662673, #0B0121);
}

.members-container::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%; /* Adjust this value to control the width of the background image */
    background: url('../../resources/background_waves.png') no-repeat right center / cover;
    z-index: 1; /* This will place the pseudo-element below the content of the .members-container */
}

.members-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
}

.members-title-section {
    display: flex;
    width: 480px;
    padding: 24px 0px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.members-title-wrapper {
    display: flex;
    width: 800px;
    padding: 10px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.members-title {
    flex: 1 0 0;
    color: #ECECEC;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
    letter-spacing: -0.32px;
}

.members-subtitle {
    align-self: stretch;
    color: #ECECEC;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
}

.members-container-content {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding-bottom: 60px;
}

/* Mobile Styles */
@media (max-width: 767px) {
    .members-container {
        padding: 30px 20px;
    }

    .members-container::before {
        width: 100%;
    }

    .members-title-section {
        width: auto;
        margin-bottom: -30px;
        z-index: 2;
    }

    .members-title-wrapper {
        width: auto;
        padding: 5px;
    }

    .members-title {
        font-size: 28px;
    }

    .members-subtitle {
        font-size: 16px;
    }

    .members-container-content {
        gap: 30px;
        padding-bottom: 30px;
        z-index: 2;
    }
}