.action-section {
    display: flex;
    /* padding-top: 60px; */
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.action-container {
    display: flex;
    width: 1200px;
    padding: 60px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 20px;
    max-width: 100%;
    box-sizing: border-box;
}

.action-content {
    display: flex;
    width: 768px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.action-content-title {
    align-self: stretch;
    color: #ECECEC;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
}

.action-content-body {
    color: #ECECEC;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
}

/* Mobile Styles */
@media (max-width: 767px) {
    .action-container {
        width: 100%;
        padding: 40px;
    }

    .action-content {
        width: auto;
    }

    .action-content-title {
        font-size: calc(2em + 1vw);
    }

    .action-content-body {
        font-size: calc(.9em + 1vw);
    }
}