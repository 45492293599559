.card-small-container {
    display: flex;
    padding: 60px;
    align-items: flex-start;
    gap: 160px;
    align-self: stretch;
    border-radius: 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%);
    box-shadow: 0px 0.5px 0px 0px rgba(255, 255, 255, 0.50) inset, 0px -2px 40px 0px rgba(187, 155, 255, 0.15), 0px -2px 10px 0px rgba(233, 223, 255, 0.30);
    z-index: 2;
}

.card-small-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.card-small-content-icon-wrapper {
    display: flex;
    width: 460px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    /* box-shadow: 0px 0px 36.45467px 0px rgba(201, 37, 171, 0.75); */
}

.card-small-content-icon-image {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    display: flex;
    align-self: center;
}

.blue-shadow {
    filter: drop-shadow(0 0 12.5px #591DDD);
}

.purple-shadow {
    filter: drop-shadow(0 0 12.5px #9525C9);
}

.pink-shadow {
    filter: drop-shadow(0 0 12.5px #C925AB);
}

.card-small-content-card-small-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: stretch;
    align-items: center;
    justify-content: center;
}

.card-small-content-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: center;
    color: #ECECEC;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 41.6px */
    letter-spacing: -0.16px;
}

.card-small-content-text {
    align-self: center;
    color: #ECECEC;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    text-align: center;
    white-space: normal;
    overflow-wrap: break-word;
    max-width: 400px;
}

@media (max-width: 767px) {
    .card-small-container {
        padding: 50px 10px;
        max-width: 90%;
        justify-content: center;
    }
}

