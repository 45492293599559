.logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-image {
    /* Width and height will be overwritten by individual logo styles */
    width: 62px;
    height: 20px;
}
