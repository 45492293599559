.hero {
    display: flex;
    padding: 110px 20px 110px 20px;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    align-self: stretch;
    background: url('../../resources/background_merged.png'), lightgray 50% / cover no-repeat;
}

.hero-container {
    display: flex;
    width: 960px;
    flex-direction: column;
    align-items: center;
    gap: 80px;
}

.hero-title-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.hero-pill {
    display: flex;
    width: 417.622px;
    padding: 9.905px 19.811px;
    justify-content: center;
    align-items: center;
    border-radius: 61.909px;
    border: 1.238px solid rgba(255, 255, 255, 0.30);
}

.hero-pill-text {
    color: #F2F4F8;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', sans-serif;
    font-size: 17.334px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 26.002px */
}

.hero-title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.hero-title-main-text {
    align-self: stretch;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 80px */
    letter-spacing: -2.4px;
    background: linear-gradient(146deg, #ECECEC 20.35%, #ECECEC 128.73%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-paragraph-wrapper {
    display: flex;
    width: 600px;
    justify-content: center;
    align-items: flex-start;
}

.hero-paragraph-text {
    flex: 1 0 0;
    color: rgba(236, 236, 236, 0.65);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%; /* 28.8px */
}

.hero-actions {
    display: flex;
    padding-top: 16px;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

.hero-image-container {
    display: flex;
    height: 472px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 24px;
    box-shadow: 0px -2px 40px 0px rgba(187, 155, 255, 0.15), 0px -2px 10px 0px rgba(233, 223, 255, 0.30), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.50) inset;
    overflow: hidden;
}

.hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Mobile view */
@media (max-width: 767px) {
    .hero {
        padding: 60px 20px;
        gap: 40px;
    }

    .hero-container {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .hero-title-section {
        gap: 16px;
    }

    .hero-pill {
        width: auto;
        padding: 10px 20px;
        border-radius: 30px;
    }

    .hero-pill-text {
        font-size: 18px;
    }

    .hero-title-main-text {
        margin-top: 10px;
        font-size: 35px;
        line-height: 1.2;
        letter-spacing: -1.0px;
    }

    .hero-paragraph-wrapper {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .hero-paragraph-text {
        font-size: 16px;
        line-height: 1.5; 
    }

    .hero-actions {
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }

    .hero-image-container {
        height: auto;
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
        position: relative;
        margin-top: -30px;
    }

    .hero-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}