/* App.css */
.App {
  text-align: center;
}

/* Button styles to be reused across the app */
.button-filled {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid #ECECEC;
  background: #ECECEC;
  color: #0B081C;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-decoration: none; /* Remove underline if used on anchor elements */
  cursor: pointer;
}

.button-outlined {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid #ECECEC;
  background: transparent;
  color: #ECECEC;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-decoration: none; /* Remove underline if used on anchor elements */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

button.button-outlined:hover {
  background-color: #ECECEC;
  color: #0B081C;
}

.button-wrapper {
  transition: transform 0.3s ease-in-out;
}

.button-wrapper:hover {
  transform: scale(1.1);  /* slightly enlarges the icon on hover */
}

/* Logo */
.logo-wrapper {
  display: flex;
  width: 138px;
  height: 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.logo {
  width: 141px;
  height: 22px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.logo-icon {
  display: inline-flex;
  width: 22px;
  height: 20px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  margin-right: 10px;
}

.logo-text {
  color: #ECECEC;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 22px */
  letter-spacing: -0.66px;
}

/* Hide certain elements on screens larger than or equal to 768px */
@media (min-width: 768px) {
  .hide-on-desktop {
      display: none;
  }
}

/* Hide certain elements on screens smaller than 768px */
@media (max-width: 767px) {
  .hide-on-mobile {
      display: none;
  }
}